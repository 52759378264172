@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS GENERAL */
ul li {
  list-style: inside;
}

.dark.bg-graymessage {
  background-color: #212641;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body {
  font-family: "Archivo";
}
.row {
  --bs-gutter-x: 0!important;
}
.link {
  color: #000000;
  text-decoration: none;
}
.link:hover {
  color: #0a58ca;
  text-decoration: none;
}
.link i {
  margin-right: 5px;
}
a {
  text-decoration: none!important;
}
.pointer {
  cursor: pointer;
}
.col-0-5 {
  flex: 0 0 auto;
  width: 4.1666666666666%!important;
}
.col-1-5 {
  flex: 0 0 auto;
  width: 12.5%!important;
}
.col-2-5 {
  flex: 0 0 auto;
  width: 20.8333333333333%!important;
}
.col-3-5 {
  flex: 0 0 auto;
  width: 29.166666666666%!important;
}
.chargement{
  position: fixed;
}
/* BACKGROUND */
.bg-transparent{
  background-color: transparent;
}
.bg-222222{
  background-color: #222222;
}
.bg-blanc{
  background-color: #FFFFFF;
}
.bg-fbfbfb{
  background-color: #fbfbfb;
}
.bg-orange{
  background-color: #FFBD00;
}
.bg-rouge{
  background-color: #FF0000;
}
.bg-rouge-FF5C5C{
  background-color: #FF5C5C;
}
.bg-bleu-violet {
  background-color: #0036ff;
}
.bg-bleu-0042ff {
  background-color: #0042ff;
}
.bg-bleu-CBF9FF {
  background-color: #CBF9FF;
}
.bg-gris-a2a2a2{
  background-color: #a2a2a2;
}
.bg-gris-ecf1f2{
  background-color: #ecf1f2;
}
.bg-beige-F9C6B7{
  background-color: #F9C6B7;
}
.bg-b3dcff{
  background: #b3dcff;
}
.bg-degrade-violet-bleu {
  background: linear-gradient(to right, #0438fa, #eb4873);
}
.bg-degrade-rouge {
  background: linear-gradient(to right, #8c0000, #ff0000);
}
.bg-degrade-orange {
  background: linear-gradient(to right, #de7101, #ffa237);
}
.bg-82bcff {
  background: #82bcff!important;
}

/* BORDER */
.bd-none {
  border: none;
}
.bd-transparent-1 {
  border: 1px solid transparent;
}
.bd-gris-646464{
  border: 1px solid #646464;
}
.bd-gris-A2A2A2{
  border: 1px solid #A2A2A2;
}
.bd-bleu-1{
  border: 1px solid #0042FF;
}
.bd-b-gris-1{
  border-bottom: 1px solid #dee2e6;
}
.bd-blanc-3{
  border: solid 3px white;
}
.bd-noir-right-5{
  border-right: solid 5px black;
}
.bd-bleu-3-left{
  border-left: 3px solid #0042FF;
}


/* BORDER RADIUS*/
.border-radius-5{
  border-radius: 5px;
}
.border-radius-9{
  border-radius: 9px;
}
.border-radius-15{
  border-radius: 15px;
}
.border-radius-25{
  border-radius: 25px;
}
.border-ombre{
  box-shadow: 0px 0px 3px #00000029;
}

/* WIDTH */
.width-200{
  width: 200px;
}
.w-2{
  width: 2%!important;
}
.w-5{
  width: 5%!important;
}
.w-10{
  width: 10%!important;
}
.w-15{
  width: 15%!important;
}
.w-20{
  width: 20%!important;
}
.w-30{
  width: 30%!important;
}
.w-33{
  width: 33%!important;
}
.w-35{
  width: 35%!important;
}
.w-40{
  width: 40%!important;
}
.w-60{
  width: 60%!important;
}
.w-65{
  width: 65%!important;
}
.w-70{
  width: 70%!important;
}
.w-80{
  width: 80%!important;
}
.w-85{
  width: 85%!important;
}
.w-90{
  width: 90%!important;
}
.w-95{
  width: 95%!important;
}
.w-98{
  width: 98%!important;
}
.w-fit-content {
  width: fit-content!important;
}

body {
  color: black !important;
  padding:0%!important;
}

.fade-in {
  -webkit-animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-18 15:19:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-18 15:42:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-18 15:58:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.h1, h1 {
  margin: 1% 0%;
}

.page {
  display: flex;
}

.header .switch {
  position: relative;
  display: inline-block;
  min-width: 52px;
  max-width: 52px;
  height: 17px;
}
.header .slider:before {
  height: 13px;
  width: 13px;
  bottom: 2px;
}

/* CSS Page login */
.imageShokola {
  background-color: #0042ff;
}

/* CSS Tableau de bord client */

/*PAGE*/
.tableauCTClient {
  width: 80%;
  margin: 0% 10%;
}

.tabTaches h3 {
  margin-left: 2%;
}

/*.tache {
  width: 20%;
  text-align: center;
  padding: 2% 1% 0% 1%;
  margin: 3%;
  border-radius: 5%;
  color: white;
  font-weight: bold;
}*/

.tache.demandee {
  background-color: #6E00D6;
}

.tache.enCours {
  background-color: #FFB200;
}

.tache.attenteRetour {
  background-color: #00B9FF;
}

.tache.cloturees {
  background-color: #2AD600;
}

.dernieresTaches tr, .dernieresTaches th, .dernieresTaches td, .dernieresTaches thead, .dernieresTaches tbody {
  border-width: 0px !important;
}

.espace {
  height: 10px;
}

.intutulesTab{
  background-color: #222222;
}

.thPuce{
  text-overflow: clip!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendeTacheTab .puce.verte, .legendeTacheTab .puce.rouge, .legendeTacheTab .puce.orange{
  position: relative;
  margin :0% -5%;
}

.tableauDashboadClient .row {
  --bs-gutter-x: 0rem;
}
.divTxtCoupe{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

/*
.dernieresTaches .puceTache{
  min-width: 3%;
  max-width: 3%;
}
.dernieresTaches .dateTache{
  min-width: 6%;
  max-width: 6%;
}
.dernieresTaches .nomTache{
  min-width: 27%;
  max-width: 27%;
}
.dernieresTaches .nomSiteTache{
  min-width: 14%;
  max-width: 14%;
}
.dernieresTaches .statutTache{
  min-width: 11%;
  max-width: 11%;
}
.dernieresTaches .facturationTache{
  min-width: 11%;
  max-width: 11%;
}
.dernieresTaches .limiteTache{
  min-width: 8%;
  max-width: 8%;
}
.dernieresTaches .periodeTache{
  min-width: 12%;
  max-width: 12%;
}

.derniersTickets .puceTicket{
  min-width: 6%;
  max-width: 6%;
}
.derniersTickets .dateTicket{
  min-width: 10%;
  max-width: 10%;
}
.derniersTickets .nomTicket{
  min-width: 30%;
  max-width: 30%;
}
.derniersTickets .nomSiteTicket{
  min-width: 20%;
  max-width: 20%;
}
.derniersTickets .statutTicket{
  min-width: 14%;
  max-width: 14%;
}
.derniersTickets .facturationTicket{
  min-width: 14%;
  max-width: 14%;
}

.societes .nom{
  max-width: 13%;
  min-width: 13%;
}
.societes .siteWeb{
  max-width: 16%;
  min-width: 16%;
}
.societes .numeroDeTelephone{
  max-width: 11%;
  min-width: 11%;
}
.societes .adresse{
  max-width: 16%;
  min-width: 16%;
}
.societes .codePostal{
  max-width: 10%;
  min-width: 10%;
}
.societes .ville{
  max-width: 12%;
  min-width: 12%;
}
.societes .creditsTemps{
  max-width: 14%;
  min-width: 14%;
}

.taches .puceTache{
  max-width: 2%;
  min-width: 2%;
}
.taches .clientTache{
  max-width: 12%;
  min-width: 12%;
}
.taches .poleTache{
  max-width: 7%;
  min-width: 7%;
}
.taches .nomTache{
  max-width: 15%;
  min-width: 15%;
}
.taches .cpTache{
  max-width: 7%;
  min-width: 7%;
}
.taches .teamTache{
  max-width: 7%;
  min-width: 7%;
}
.taches .statutTache{
  max-width: 8%;
  min-width: 8%;
}
.taches .facturationTache{
  max-width: 8%;
  min-width: 8%;
}
.taches .dateDemandeTache{
  max-width: 7%;
  min-width: 7%;
}
.taches .dateLimiteTache{
  max-width: 7%;
  min-width: 7%;
}
.taches .tempsVenduTache{
  max-width: 4%;
  min-width: 4%;
}
.taches .tempsPasseTache{
  max-width: 4%;
  min-width: 4%;
}

.table-mes-taches-ct .puceTache,
.table-mes-estimations .puceEstimation,
.table-tickets-a-traiter .puceTicket{
  max-width: 8%;
  min-width: 8%;
}
.table-mes-taches-ct .clientTache,
.table-mes-estimations .clientEstimation,
.table-tickets-a-traiter .clientTicket{
  max-width: 24%;
  min-width: 24%;
}
.table-mes-taches-ct .nomTache,
.table-mes-estimations .nomEstimation,
.table-tickets-a-traiter .nomTicket{
  max-width: 52%;
  min-width: 52%;
}
.table-mes-taches-ct .dateTache,
.table-mes-estimations .dateEstimation,
.table-tickets-a-traiter .dateTicket{
  max-width: 16%;
  min-width: 16%;
}
/*
.serveurs .nom{
  max-width: 15%;
  min-width: 15%;
}
.serveurs .ip{
  max-width: 12%;
  min-width: 12%;
}
.serveurs .hoteFtp{
  max-width: 18%;
  min-width: 18%;
}
.serveurs .sshFtpUser{
  max-width: 12%;
  min-width: 12%;
}
.serveurs .sshFtpMdp{
  max-width: 12%;
  min-width: 12%;
}
.serveurs .hoteBdd{
  max-width: 18%;
  min-width: 18%;
}
.serveurs .couleur{
  max-width: 13%;
  min-width: 13%;
}

.tickets .puceTache{
  max-width: 2%;
  min-width: 2%;
}
.tickets .limiteTache,
.tickets .tempsPasseTache{
  max-width: 4%;
  min-width: 4%;
}
.tickets .dateTache{
  max-width: 8%;
  min-width: 8%;
}
.tickets .nomTache{
  max-width: 23%;
  min-width: 23%;
}
.tickets .nomSiteTache{
  max-width: 15%;
  min-width: 15%;
}
.tickets .poleTache{
  max-width: 10%;
  min-width: 10%;
}
.tickets .statutTache,
.tickets .facturationTache{
  max-width: 10%;
  min-width: 10%;
}
.tickets .cpTache{
  max-width: 9%;
  min-width: 9%;
}

.achats .numFacturationAchat{
  max-width: 16%;
  min-width: 16%;
}
.achats .clientAchat{
  max-width: 16%;
  min-width: 16%;
}
.achats .interlocuteurAchat{
  max-width: 16%;
  min-width: 16%;
}
.achats .dateAchat{
  max-width: 16%;
  min-width: 16%;
}
.achats .ctAchat{
  max-width: 16%;
  min-width: 16%;
}
.achats .factureAchat{
  max-width: 16%;
  min-width: 16%;
}

.sites .actif{
  max-width: 5%;
  min-width: 5%;
}
.sites .nom{
  max-width: 22%;
  min-width: 22%;
}
.sites .client{
  max-width: 19%;
  min-width: 19%;
}
.sites .commercial{
  max-width: 12%;
  min-width: 12%;
}
.sites .contact{
  max-width: 12%;
  min-width: 12%;
}
.sites .serveur{
  max-width: 15%;
  min-width: 15%;
}
.sites .cmsFramework{
  max-width: 15%;
  min-width: 15%;
}
*/

.taches .col, .tickets .col, .derniersTickets .col{
  padding: 0;
  margin-right: 1%;
}

.titre.puceTache .puce,
.titre.puceEstimation .puce,
.titre.puceTicket .puce{
  min-width: 10px !important;
  max-width: 10px !important;
  height: 10px!important;
}

.titre.puceTache .puce.verte, .titre.puceTache .puce.orange, .titre.puceTache .puce.rouge,
.titre.puceEstimation .puce.verte, .titre.puceEstimation .puce.orange,.titre.puceEstimation .puce.rouge,
.titre.puceTicket .puce.verte, .titre.puceTicket .puce.orange, .titre.puceTicket .puce.rouge{
  margin-right: -5px;
}

.allTaches .titre.puceTache .puce {
  width: 17px!important;
  height: 17px !important;
}

.row.ligne{
  background-color: #FFFFFF;
}
.row.ligne.tachecachee{
  background-color: #82bcff !important;
}
.tachecachee{
  background-color: #82bcff !important;
}

.taches .photoDeProfil img {
  margin-right: -20%;
}

.legendeTacheTab{
  height: 60px;
}

.legendeTacheTab th{
  height: 60px;
  font-weight: lighter;
  font-size: 0.8em;
}

.legendeTacheTab th:first-child, .tacheTR td:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.legendeTacheTab th:last-child, .tacheTR td:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.divInTD {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6%;
  border-radius: 9px;
  text-align: center;
  width: 100%;
}

.CTClient {
  background-color: #FFFFFF;
  border-radius: 2px;
}

.CT {
  width: 40%;
  margin: 7% 30%;
  padding: 3%;
  border-radius: 5%;
  color: white;
  font-weight: bold;
}

.CTClient .progress {
  text-align: center;
  display: block;
  height: auto;
  overflow: unset;
  font-size: .75rem;
  background-color: transparent;
  border-radius: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barOverflow {
  position: relative;
  width: 300px;
  height: 150px;
  margin-bottom: -14px;
  overflow: hidden;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 40px solid transparent;
  border-top-color: #ccc;
  border-left-color: #ccc;
  background: linear-gradient(to right, #0438fa, #eb4873) border-box;
  display: flex;
  align-items: center;
  color: #8F8F8F;
}

.bar:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: white;
  border-radius: 120px;
}

@media screen and (max-width: 1600px) {
  .barOverflow {
    width: 250px;
    height: 125px;
  }

  .bar {
    width: 250px;
    height: 250px;
    border: 37px solid transparent;
    border-top-color: #ccc;
    border-left-color: #ccc;
  }
}

@media screen and (max-width: 1400px) {
  .barOverflow {
    width: 200px;
    height: 100px;
  }

  .bar {
    width: 200px;
    height: 200px;
    border: 29px solid transparent;
    border-top-color: #ccc;
    border-left-color: #ccc;
  }
}

@media screen and (max-width: 1100px) {
  .barOverflow {
    width: 150px;
    height: 75px;
  }

  .bar {
    width: 150px;
    height: 150px;
    border: 23px solid transparent;
    border-top-color: #ccc;
    border-left-color: #ccc;
  }
}

.infoBar {
  margin-top: -15%;
  z-index: 1;
}

.infoBar .valBar {

}

.valBar .valCT {
  font-size: 3em;
  font-weight: bold;
}

.valBar .valACT {
  font-size: 2em;
}

.infoBar .valBar p {
  display: inline;
}

.infoBar .ctEnCours {
  background-color: #fca748;
  padding: 4%;
  border-radius: 6px;
  color: white;
  font-size: 1.3em;
  margin-bottom: 3%;
}

.infoBar .ctReel {
  font-size: 1.5em
}

.CTClient a {
  color: white;
  background-color: #0036ff;
  text-decoration: none;
  border-radius: 25px;
  padding: 3% 5%;
  width: 90%;
  margin: 3% 0%;
  font-size: 1.3em;
}

.CTClient a:hover {
  background-color: #000000;
  color: white!important;
}

.camembertTaches {
  width: 30%;
  margin: 2% 10%;
  padding: 2%;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Autre */
.navigationTabClient a {
  text-decoration: none;
  padding: 0% 1%;
  border-right: solid 1px black;
}

.navigation a{
  text-decoration: none;
  padding: 0% 1%;
  font-weight: bold;
}

/* Autre */
.nomTache {
  width: 25%;
}

.nomSiteTache {
  width: 15%;
}

.cpTache {
  width: 15%;
}

.statutTache {
  width: 10%;
  text-align: center;
}

.facturationTache {
  width: 10%;
  text-align: center;
}

.actionTache {
  width: 10%;
  text-align: center;
}

.tempPasseTache {
  width: 10%;
}

/* Formulaires */
.form {
  position: relative;
  top: 1px;
}

div[id="tache_chefDeProjet"] label {
  width: 10%;
}

.form .checkbox label{
  width: auto;
  margin: 0% 2%;
}
.form .checkbox input{
  width: auto;
}

.form .date.site select{
  width: 31%;
  margin: 1%;
  padding: 0.5rem;
}

/* CSS Page liste des sites */
.tableSite {
  display: flex;
}

.tableNom .table {
  border-right-color: transparent;
}

.tableNom td {
  height: 67px;
}

.tableAutre {
  overflow: auto;
  white-space: nowrap;
  width: 80%;
  margin: 2% 10%;
}

.puce {
  min-width: 15px!important;
  max-width: 15px!important;
  height: 15px;
  margin-right: -8px;
  display: inline-block;
  border-radius: 100%;
}

.puce.rouge {
  background-color: #ff5b57;
}

.puce.verte {
  background-color: #7dc800;
}

.puce.orange {
  background-color: #fca748;
}
.puce.bleue {
  background-color: #3FB6BE;
}

.puce.grise {
  background-color: #cccccc;
}

.puceNotification{
  width: 15px;
  height: 15px;
}

.infosTache.modal.show .puce{
  width: 20px;
  height: 20px;
}


td a {
  color: #00e1ff;
  text-decoration: underline;
}

/* CSS Utilisateur show */
.ficheUtilisateur {
  display: flex;
  width: 90%;
  margin: 0% 5%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.autresInfo {
  width: 70%;
  padding: 2%;
}

/* MODAL */
.infosTache.show{
  display: flex!important;
}

.infosTache .modal-dialog{
  margin: 0;
  min-width: 100%;
}

.infosTache .modal-content{
  height: 100%;
  width: 50%;
  padding-right: 1%;
  margin-left: 51%;
}

.infosTache .modal-header{
  justify-content: flex-start;
}

.modal-body {
  max-height: 39em;
  overflow-y: scroll;
}

.addFichier .modal-dialog {

  margin: 0;
  margin-left: 66%;
  max-width: 35%;
}

.addFichier .modal-content {
  border:0;
  border-radius: 0;
}

.menu ul,
.societes ul{
  list-style-type: none;
}

.menu li,
.societes li{
  display: inline-block;
}

.tache .photoDeProfil label {
  display: block;
  position: relative;
  cursor: pointer;
  margin-top: -40px;
}

/*.photoDeProfil label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid #0042ff;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  transition-duration: 0.4s;
  transform: scale(0);
  z-index: 1;
}*/

/*.photoDeProfil :checked + label:before {
  content: "✓";
  background-color: #0042ff;
  transform: scale(1);
}*/

.photoDeProfil :checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

grammarly-desktop-integration{
  display: none;
}

.tache .photoDeProfil input{
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 30px;
}

.chefDeProjet .photoDeProfil label, .personneEnCharge .photoDeProfil label {
  margin-top: -40px;
}

.infoPhoto{
  margin-top: -40px;
  margin-left: 40px;
  border-radius: 5px;
  display: none;
  z-index: 1;
}

.infoPhoto-left {
  margin-top: -40px;
  margin-left: -193px;
  border-radius: 5px;
  display: none;
  z-index: 1;
}

.photo {
  margin-left: -20%;
}

.photo:first-child{
  margin-left: 0;
}

.photo:hover .infoPhoto{
  display: block;
}

embed img {
  width: 30%!important;
}

.pointer {
  cursor: pointer;
}

#notification #nombre,
#notificationMobile #nombreMobile{
  display: flex;
  background: #FF5C5C;
  position: relative;
  left: -30px;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  top:10px;
}

.bg-opacite-50 {
  opacity: 50%;
}
.bg-opacite-50:hover {
  opacity: 100%;
}

.text-info-icon {
  position: absolute;
  margin-top: -61px;
  margin-left: -22px;
  padding: 7px 13px;
}

.icon-hover:hover .text-info-icon {
  display: block!important;
}

.cardAdj:hover {
  background: black!important;
}

.site.serveur a:hover{
  background-color: #00fff5!important;
  color: black;
}

.nb-sous-tache {
  background: #0036ff;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  color: white;
  margin-left: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.txt-sous-tache{
  position: absolute;
  background: #0036ff;
  margin-top: -60px;
  margin-left: -33px;
  border-radius: 5px;
  display: none;
  padding: 5px 10px;
  z-index: 1;
  color: white;
}

.block-under-task:hover .txt-under-task{
  display: block!important;
}

.row-vue-client {
  background-color: #0036ff;
}
.row-vue-client:hover {
  background-color: #222222;
}

.text-notification br{
  display: none!important;
}

.txt-checklist {
  position: absolute;
  background: #000000;
  margin-top: -60px;
  margin-left: -33px;
  border-radius: 5px;
  display: none;
  padding: 5px 10px;
  z-index: 1;
  color: white;
}

.block-checklist:hover .txt-checklist{
  display: block;
}

.contenuMessage img {
  width: auto;
  max-width: 100%;
}

.colorMention {
  background: #00E1FF;
  border-radius: 25px;
  padding: 3px 5px;
}

.dark .colorMention {
  background: #935e00;
}

.bg-82bcff .gris-A2A2A2 {
  color: #262736;
}

.checklist-message input[type="checkbox"]:checked + label {
  text-decoration: line-through;
}

.adminlist a div:hover {
  background-color: black!important;
}